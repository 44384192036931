import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Tooltip as ReactTooltip} from "react-tooltip";
import Dropdown from "react-bootstrap/Dropdown";
import './AddGenre.scss';
import Button from "../Button/Button";
import {Trans, useTranslation} from 'react-i18next';
import {isEmpty, isValidGenre} from "../../Services/utils";
import {addGenre} from "../../Services/Actions/GenresAction";
import {en} from "../../Services/constants";
import SendingNotAllowedWarning from "../SendingNotAllowedWarning/SendingNotAllowedWarning";
import Reaptcha from "reaptcha";
import {checkIP, checkMiddleWare} from "../../Services/Actions/MessageActions";
import {getUserIP} from "../../Services/Actions/UserActions";
import Loading from "../Loading/Loading";

const AddGenre = (props) => {

    const captchaRef = useRef(null);
    const formRef = useRef(null);

    const {t} = useTranslation();

    const initialGenre = {code: '', translation: '', parent: null, alias: ''};
    const [genre, setGenre] = useState(initialGenre);

    const initialFormState = useMemo(() => {
        return {
            refreshingAfterNewGenre: false,
            showSentText: false,
            withParent: false,
            buttonDisabled: true,
            clearDisabled: true,
            verified: false,
            sendingError: false,
            errorText: '',
            connectingError: false,
            addingAlias: false
        }
    }, []);


    const [loaded, setLoaded] = useState(false);
    const [sendingAllowed, setSendingAllowed] = useState(false);

    const [formState, setFormState] = useState(initialFormState);

    const checkMW = useCallback(() => {
        if (!formState.connectingError) {
            checkMiddleWare().catch((err) => {
                console.log(err);
                setFormState({...formState, connectingError: true});
                setLoaded(true);
            });
        }
    }, [formState]);

    const prepopulateAlias = useCallback(() => {
        if (props.aliasTo && props.aliasTo !== genre.code) {
            setGenre({...genre, code: props.aliasTo});
            setFormState({
                ...formState,
                addingAlias: true
            });
            setLoaded(true);
        }
    }, [formState, genre, props.aliasTo]);

    const prepopulateNewGenre = useCallback(() => {
        if (props.newGenreName) {
            genre.code = props.newGenreName;
            formRef.current?.scrollIntoView();
            if (props.checkExistingGenre(genre.code)) {
                setFormState({
                    ...formState,
                    sendingError: true,
                    buttonDisabled: true,
                    errorText: <Trans i18nKey="genres.genreExists" components={[genre.code]}/>
                });
            }
        }
    }, [props, formState, genre]);

    const dropdownItems = [];

    props.parentGenres.forEach(function (item) {
        if (item.children.length > 0 && item.code !== 'other') {
            dropdownItems.push(
                <Dropdown.Item value={item.code} key={item.code} onClick={() => {
                    setGenre({...genre, parent: item});
                    setFormState({
                        ...formState,
                        buttonDisabled: disableButton(genre.code, genre.translation, formState, item, '', formState.verified),
                    });
                }}>
                    {item.translation}
                </Dropdown.Item>
            )
        }
    });

    const parentDropdown = () => {
        return (<div className="mb-2 flex-grow-1 flex-sm-grow-2"><Dropdown className="parent-dropdown">
            <Dropdown.Toggle>
                {genre?.parent?.translation}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {dropdownItems}
            </Dropdown.Menu>
        </Dropdown>
        </div>)
    }

    const codeChanged = (event) => {
        let gnr = {...genre, code: event.target.value};
        setGenre(gnr);
        setFormState({
            ...formState,
            sendingError: false,
            buttonDisabled: disableButton(gnr.code, genre.translation, formState, genre.parent, '', formState.verified),
        });
    }

    const aliasChanged = (event) => {
        let gnr = {...genre, alias: event.target.value, translation: ''};
        setGenre(gnr);
        setFormState({
            ...formState,
            sendingError: false,
            buttonDisabled: disableButton(gnr.code, '', formState, null, gnr.alias, formState.verified),
        });
    }

    const nameChanged = (event) => {
        let gnr = {...genre, translation: event.target.value, alias: ''};
        setGenre(gnr);
        setFormState({
            ...formState,
            buttonDisabled: disableButton(genre.code, gnr.translation, formState, genre.parent, '', formState.verified, formState.sendingError),
        });
    }

    const disableButton = (code, translation, formState, parent, alias, verified, sendingError = false) => {
        return isEmpty(code) || (!formState.addingAlias && !isValidGenre(code)) || (!formState.addingAlias && isEmpty(translation)) || (formState.withParent && !parent)
            || (formState.addingAlias && !isValidGenre(alias)) || sendingError || !verified;
    }

    const clear = () => {
        setGenre(initialGenre);
        setFormState(initialFormState);
    }

    const onVerify = () => {
        setFormState({
            ...formState,
            buttonDisabled: disableButton(genre.code, genre.translation, formState, genre.parent, genre.alias, true, formState.sendingError),
            verified: true
        });
    }

    const onExpire = () => {
        setFormState({
            ...formState,
            verified: false,
            buttonDisabled: true,
        });
    }

    const clearForm = () => {
        setFormState(initialFormState);
        setGenre(initialGenre);
        props.onCancel();
    }

    const send = () => {
        if (formState.buttonDisabled) {
            return;
        }
        if (formState.addingAlias && props.checkExistingGenre(genre.alias)) {
            setFormState({
                ...formState,
                sendingError: true,
                buttonDisabled: true,
                errorText: <Trans i18nKey="genres.genreExists" components={[genre.alias]}/>
            });
        } else if (!formState.addingAlias && props.checkExistingGenre(genre.code)) {
            setFormState({
                ...formState,
                sendingError: true,
                buttonDisabled: true,
                errorText: <Trans i18nKey="genres.genreExists" components={[genre.code]}/>
            });
        } else {
            getUserIP().then(res => {
                setFormState({...formState, refreshingAfterNewGenre: true});
                addGenre({
                    ...genre,
                    lang: props.lang ? props.lang : en,
                    ip: res?.data?.ip_address
                }).then(() => {
                    checkIP(res?.data?.ip_address).then((res) => {
                        setFormState({...initialFormState, showSentText: true});
                        setSendingAllowed(res);
                        props.onAdding(genre);
                    });
                }).catch((err) => {
                    console.log(err);
                    setFormState({...formState, sendingError: true, errorText: t('feedback.sendingError')});
                });
            }).catch((err) => {
                console.log(err);
                setFormState({...formState, sendingError: true, errorText: t('feedback.sendingError')});
            });
        }
    }

    useEffect(() => {
        checkMW();
        if (!loaded) {
            getUserIP().then((res) => {
                checkIP(res?.data?.ip_address).then((res1) => {
                    setSendingAllowed(res1);
                    setLoaded(true);
                    prepopulateNewGenre();
                });
            });
        }
        prepopulateAlias();

    }, [checkMW, loaded, prepopulateNewGenre, prepopulateAlias]);

    const clearFormWhenAliasSent = useCallback(() => {
        if (props.aliasTo) {
            setFormState({...formState, showSentText: false});
        }
    }, [props.aliasTo, formState]);

    useEffect(() => {
        clearFormWhenAliasSent();
    }, [clearFormWhenAliasSent]);

    return (
        <>
            <div className="row">
                <div className="col-12 mt-2">
                    <h3 id="add" ref={formRef}>{t('genres.add')}</h3>
                </div>
            </div>
            {!loaded &&
            <Loading/>
            }
            {loaded && <>
                {formState.connectingError &&
                <div className="error global">{t('feedback.connectingError')}</div>
                }
                {!formState.connectingError &&
                <>
                    {formState.sendingError &&
                    <div className="error global">{formState.errorText}</div>
                    }
                    {formState.showSentText &&
                    <>
                        <div className="row mb-2">
                            <div className="col-12">
                                {t('genres.viewTitle')}
                            </div>
                        </div>
                        {genre.alias === '' &&
                        <div className="row mb-2">
                            <div className="col-12 col-sm-2">
                                <div className="d-inline-block font-weight-bold mr-2">{t('genres.code')}:</div>
                                <div className="d-inline-block mr-2">{genre.code}</div>
                            </div>
                            <div className="col-12 col-sm-4">
                                <div className="d-inline-block font-weight-bold mr-2">{t('genres.name')}:</div>
                                <div className="d-inline-block mr-2">{genre.translation}</div>
                            </div>
                            <div className="col-12 col-sm-6">
                                {genre.parent &&
                                <>
                                    <div className="d-inline-block font-weight-bold mr-2">{t('genres.viewParent')}:
                                    </div>
                                    <div className="d-inline-block">{genre.parent.translation}</div>
                                </>
                                }
                            </div>
                        </div>}
                        {genre.alias !== '' &&
                        <div className="row mb-2">
                            <div className="col-12 col-sm-2">
                                <div className="d-inline-block font-weight-bold mr-2">{t('genres.code')}:</div>
                                <div className="d-inline-block mr-2">{genre.code}</div>
                            </div>
                            <div className="col-12 col-sm-4">
                                <div className="d-inline-block font-weight-bold mr-2">{t('genres.alias')}:</div>
                                <div className="d-inline-block mr-2">{genre.alias}</div>
                            </div>
                        </div>}
                        {sendingAllowed &&
                        <div className="row mb-2">
                            <div className="col-12">
                                <Button onClick={clear} text={t('genres.viewSwitchButton')}/>
                            </div>
                        </div>
                        }
                        {!sendingAllowed &&
                        <SendingNotAllowedWarning/>
                        }
                    </>
                    }
                    {!formState.showSentText &&
                    <>
                        {formState.refreshingAfterNewGenre && <><Loading/></>}
                        {!formState.refreshingAfterNewGenre &&
                        <>
                            {sendingAllowed &&
                            <>
                                <div className="row">
                                    <div className="col-12 mt-2 mb-2 form-wrapper">
                                        <div className="mr-2 mb-2 flex-grow-1 with-checkbox">
                                            <div
                                                className="input-wrapper d-inline-block mr-1">{t('genres.withParent')}</div>
                                            <input
                                                type="checkbox"
                                                className="mx-1 px-1"
                                                value={formState.withParent}
                                                disabled={formState.addingAlias}
                                                onChange={() => {
                                                    setFormState({
                                                        ...formState,
                                                        withParent: !formState.withParent,
                                                        buttonDisabled: disableButton(genre.code, genre.translation, formState, genre.parent, '', formState.verified)
                                                    });
                                                    setGenre({
                                                        ...genre,
                                                        parent: null
                                                    });
                                                }}/>
                                        </div>
                                        {formState.withParent && parentDropdown()}
                                        <div className="mb-2 flex-grow-1 flex-sm-grow-2">
                                            <div className="input-wrapper d-inline-block mr-2 code">{t('genres.code')}:
                                                <span data-tooltip-id="add-genre" className="info">i</span>
                                                <ReactTooltip
                                                    id="add-genre"
                                                    place="bottom"
                                                    variant="info"
                                                    className="genre-info"
                                                    content={
                                                        <Trans i18nKey="genres.info" components={['<', '>', <b/>]}/>
                                                    }
                                                />
                                            </div>
                                            <input type="text" value={genre.code} onChange={codeChanged}
                                                   disabled={formState.addingAlias}
                                                   className="mr-sm-2 px-1"/>
                                        </div>
                                        {formState.addingAlias &&
                                        <div className="mb-2 flex-grow-1 flex-sm-grow-2">
                                            <div className="input-wrapper d-inline-block mr-2 code">{t('genres.alias')}:
                                            </div>
                                            <input type="text" value={genre.alias} onChange={aliasChanged}
                                                   className="mr-sm-2 px-1"/>
                                        </div>
                                        }
                                        {!formState.addingAlias &&
                                        <div className="mb-2 flex-grow-1 flex-sm-grow-2">
                                            <div className="input-wrapper d-inline-block mr-2 code">{t('genres.name')}:
                                            </div>
                                            <input type="text" value={genre.translation} onChange={nameChanged}
                                                   className="mr-sm-2 px-1"/>
                                        </div>
                                        }
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-12">
                                        <div className="d-inline-block mr-2 captcha-wrapper">
                                            <Reaptcha
                                                sitekey={process.env.REACT_APP_SITE_KEY}
                                                ref={captchaRef}
                                                onVerify={onVerify}
                                                onExpire={onExpire}
                                            />
                                        </div>
                                        <div className="d-inline-block send-button-wrapper mb-2 mb-sm-0">
                                            <Button onClick={send} disabled={formState.buttonDisabled}
                                                    className="w-100 text-center"
                                                    text={t('feedback.button')}/>
                                        </div>
                                        <div className="d-inline-block send-button-wrapper ml-sm-1">
                                            <Button onClick={clearForm}
                                                    className="w-100 text-center"
                                                    text={t('feedback.cancel')}/>
                                        </div>
                                    </div>
                                </div>
                            </>
                            }</>
                        }
                        {!sendingAllowed &&
                        <SendingNotAllowedWarning/>
                        }
                    </>
                    }
                </>
                }
            </>
            }
        </>
    )
}

export default AddGenre