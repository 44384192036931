import i18n from 'i18next';
import {en, genresParsing} from './constants';

export function setTitleAndLanguage(titleString, langParam) {
    if (langParam !== undefined && i18n.language !== langParam) {
        i18n.changeLanguage(langParam);
    }
    window.history.replaceState({lang: langParam}, titleString);
    document.title = titleString.replaceAll('<br/>', '');
}

export function setLanguageOnGoBack(lang) {
    if (lang === undefined) {
        lang = en;
    }
    if (i18n.language !== lang) {
        i18n.changeLanguage(lang);
    }
}

export function determineLang(window_history, lang) {
    return ((window_history.state === null || window_history.state['lang'] === undefined) ? lang : window_history.state['lang']) ?? en;
}

export function processParametrizedUrl(fromParametrizedUrl, setFromParametrizedUrl, titleString, langParam, url) {
    if (fromParametrizedUrl) {
        setTitleAndLanguage(titleString, langParam, url);
        setFromParametrizedUrl(false);
    }
}

export function isEmpty(str) {
    return str.trim().length === 0
}

export function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
}

export function isValidGenre(genre) {
    return /^[a-z_]{3,}(_\d+)?$/.test(genre);
}

export function adjstLang(lang) {
    if (lang === 'ua') {
        return 'uk';
    }
    return lang;
}

export function checkCustomGenre(hContent, customMarkerIndex, genre_code) {
    const vlIndex = hContent.indexOf(` ${genre_code},`);
    return vlIndex > customMarkerIndex || hContent.substring(vlIndex, vlIndex + genre_code.length + genresParsing.custom_marker_single.length + 3).indexOf(genresParsing.custom_marker_single) > -1;
}

export function wrapToJson(str) {
    const lastCommaIndex = str.lastIndexOf(',');
    return JSON.parse('{' + str.substring(0, lastCommaIndex) + '}');
}

export function wrapToJson1(str) {
    const lastCommaIndex = str.lastIndexOf(',');
    return JSON.parse('[' + str.substring(0, lastCommaIndex) + ']');
}