import {backEndServerAddress, backEndServerPort, genres} from "../constants";

export async function getGenres() {

    const str = `${backEndServerAddress}:${backEndServerPort}/${genres}`;
    try {
        const response = await fetch(str);
        return await response.json();
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function addGenre(genre) {
    const nameAdjusted = genre.parent? genre.translation.toLowerCase() : genre.translation;
    let requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
            code: genre.code,
            name: nameAdjusted,
            parentCode: genre.parent?.code,
            lang: genre.lang,
            ip: genre.ip,
            alias: genre.alias
        })
    };
    try {
        const response = await fetch(`${backEndServerAddress}:${backEndServerPort}/${genres}`, requestOptions);
        return await response.json();
    } catch (error) {
        console.error(error);
        throw error;
    }
}